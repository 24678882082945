.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.responsive {
  max-height: 20px;
  max-width: 150px;
}
.responsiveAnimales {
    max-height: 35px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 20px; 
    margin-top: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.restOfScreen{
    background-image: url("/fondo.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForReporteScreen{
    background-image: url("/images/reportes/seccion_reportes.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForConozcanosScreen{
    background-image: url("/images/conozcanos/seccion_conozcanos.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

}

.restOfScreenForInicioScreen{
    background-image: url("/fondo.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;

}

.restOfScreenForColaboraScreen{
    background-image: url("/images/colabora/seccion_colabora.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForEducacionScreen{
    background-image: url("/images/educacion/seccion_educacion.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForContactanosScreen{
    background-image: url("/images/contactanos/seccion_contactanos.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForSumateScreen{
    background-image: url("/images/sumate/seccion_sumate.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
  
.fondoTransparente {
  background-color: rgba(255, 255, 255, .93);
  margin: 0 15% 0 15%;
  border-radius: 5px;
  padding-bottom: 20px;
}

.fondoPag {
  background-color: rgba(255, 255, 255, .93);
  border-radius: 5px;
  display: block;
  white-space: pre;
}

.bottomText{
  vertical-align:bottom;
  position: fixed;
  bottom: 0;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px; 

  color: #a8a8a8  ;
  font-size: 9px;
}
.section {    
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
    margin: 0 auto;      
}

.left {
    position: relative;
    float: left; 
}

.right {
    float: right;
    position:relative;
}

#newLine { width:100% ; height:10px; }

.makeStyles-content-4 {
    padding:0px !important;
}

.jss4 {
    padding:0px !important;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.imgCarousel {
    max-width: 100%;
    max-height: 900px;
}

.makeStyles-toolbar-5{
    min-height: auto !important;
}

.jss5{
    min-height: auto !important;
}

.imgOne{   
    background-image: url("/images/carousel_images/1.jpg"); 
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:  contain;
}

.imgTwo {
    background-image: url("/images/carousel_images/2.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgTree {
    background-image: url("/images/carousel_images/3.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgFour {
    background-image: url("/images/carousel_images/4.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgFive {
    background-image: url("/images/carousel_images/5.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgSix {
    background-image: url("/images/carousel_images/6.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgSeven {
    background-image: url("/images/carousel_images/7.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}